import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import 'typeface-source-sans-pro'
import { Link } from '@reach/router'
import { StaticImage } from 'gatsby-plugin-image'

const IndexPage = () => (
  <Layout>
    <Seo
      title="Tiefenpsychologisch orientierte Beratung"
      description="Stefan Schopf bietet tiefenpsychologisch orientierte Beratung
             und Coaching für Einzelpersonen, Paare und Familien in München an."
      keywords={[
        'Beratung',
        'Paarberatung',
        'Coaching',
        'Familienberatung',
        'Erziehungsberatung',
        'München',
        'Schopf',
        'Stefan',
      ]}
    />
    <article>
      <div className="fl w-100 w-50-ns pr2">
        <StaticImage
          src={'../images/kompass.jpg'}
          alt="Kletterer"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="fl w-100 w-50-ns">
        <h1>Coaching - Paarberatung - Familienberatung in München</h1>
        <p>
          Das Leben führt manchmal an einen Punkt, an dem deutlich wird, dass
          etwas zu klären ist, oder man spürt, dass es neuer Impulse bedarf, um
          aus einer Sackgasse herauszufinden.
        </p>
        <p>
          Aus <Link to="/ueber-mich/">meiner langjährigen Erfahrung</Link> weiß
          ich, wie schwer es ist, sich mit einem persönlichen Problem nach außen
          zu wenden.
        </p>
        <p>
          Es besteht die Befürchtung, sich gedanklich und emotional zusätzlich
          zu belasten, wenn man über das, was einem zusetzt, spricht.
          Andererseits konnte ich aber auch häufig miterleben, wie stark das
          Gefühl der Erleichterung nach einer gelungenen{' '}
          <Link to="/termine">Beratungssitzung</Link> sein kann.{' '}
        </p>
        <p>
          Ich biete Ihnen an, Sie in der Auseinandersetzung mit sich und Ihrer
          Lebenssituation im <Link to="/einzelberatung/">Coaching</Link>{' '}
          fachkundig zu begleiten oder mit Ihnen in der{' '}
          <Link to="/paarberatung/">Paarberatung</Link> oder{' '}
          <Link to="/familienberatung/">Familienberatung</Link> Konfliktthemen
          zu bearbeiten.
        </p>
        <p className="b">
          Wenn Sie in Ihrem Leben etwas verändern wollen, ist jetzt der richtige
          Zeitpunkt, damit zu beginnen.
        </p>
        <p>
          <em>Stefan Schopf</em>
        </p>
      </div>
    </article>
  </Layout>
)

export default IndexPage
